#ctaWpp {
    background: #09CE58;
    font-weight: 700;
    padding: .3rem .5rem;
    width: 30%;
    height: fit-content;
    border-radius: 5px;
}

#ctaWpp a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;
}

#ctaWpp a figure {
    width: 14%;
    margin-top: .2rem;
}

#ctaWpp a figure img {
    width: 100%;
}

#ctaWpp p {
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp {
        width: 60%;
    }

    #ctaWpp a figure {
        width: 12%;
    }

    #ctaWpp p {
        font-size: 22px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaWpp {
        width: 80%;
    }

    #ctaWpp p {
        font-size: 20px;
    }
}