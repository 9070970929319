#menu .container {
    display: grid;
    grid-template-columns: 30% 70%;
    padding: .5rem 0;
}

#menu .container .left {
    text-align: left;
}

#menu .container .left img {
    width: 30%;
}

#menu .container .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}

#menu .container .right button {
    width: 35%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container {
        grid-template-columns: 100%;
    }

    #menu .container .left {
        text-align: center;
    }

    #menu .container .right {
        display: none;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container .left img {
        width: 25%;
    }
}