#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

#header .header1 .container .title {
    max-width: 390px;
    color: white;
    line-height: 4.5rem;
}

#header .header1 .container .title span {
    font-size: 106px;
}

#header .header1 .container .text {
    max-width: 450px;
    color: white;
}

#header .header2 {
    background-color: #0085FF;
}

#header .header2 .container {
    padding: 1rem 0;
}

#header .header2 .container .text {
    text-align: center;
    color: white;
}

#header .header3 {
    background: url("../../assets/header3_bg.png") no-repeat center center;
    background-size: cover;
}

#header .header3 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 4rem;
}

#header .header3 .container .right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header3 .container .right .mobile {
    display: none;
}

#header .header3 .container .right #ctaWpp {
    width: 65%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center 0;
        background-size: cover;
        height: 85vh;
    }

    #header .header1 .container {
        justify-content: flex-end;
    }

    #header .header1 .container .title {
        max-width: 100%;
    }

    #header .header1 .container .title span {
        font-size: 92px;
    }

    #header .header1 .container .text {
        max-width: 100%;
        text-align: center;
    }

    #header .header1 .container #ctaWpp {
        margin: 0 auto;
    }

    #header .header3 .container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    #header .header3 .container .left {
        display: none;
    }

    #header .header3 .container .right .mobile {
        display: block;
    }

    #header .header3 .container .right .mobile img {
        width: 70%;
    }

    #header .header3 .container .right #ctaWpp {
        width: 60%;
        margin: 0 auto;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 75vh;
    }

    #header .header1 .container .title span {
        font-size: 79px;
    }

    #header .header3 .container .right #ctaWpp {
        width: 80%;
    }
}