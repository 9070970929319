#footer .footer1 {
    background-color: #1E2021;
}

#footer .footer1 .container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}

#footer .footer1 .container .left {
    text-align: center;
}

#footer .footer1 .container .left img {
    width: 15%;
}

#footer .footer1 .container .right {
    display: none;
}

#footer .footer1 .container .right .text {
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
}

#footer .footer1 .container .right .imgs {
    display: flex;
    align-items: center;
    gap: .5rem;
}

#footer .footer1 .container .right .imgs figure {
    width: 120px;
    height: 60px;
}

#footer .footer1 .container .right .imgs .img2 {
    width: 165px;
}

#footer .footer1 .container .right .imgs figure img {
    height: 100%;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#footer .footer2 .container p {
    font-size: 20px;
    color: #000000;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    #footer .footer1 .container .left {
        text-align: center;
    }

    #footer .footer1 .container .left img {
        width: 25%;
    }

    #footer .footer1 .container .right {
        align-items: center;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
    }

    #footer .footer2 .container p {
        font-size: 18px;
        text-align: center;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container .left img {
        width: 30%;
    }

    #footer .footer2 .container p {
        font-size: 16px;
    }
}