#about .about1 .container .title {
  text-align: center;
}

#about .about1 .container .text {
  text-align: center;
}

#about .about1 .container .gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 1rem;
}

#about .about1 .container .gallery .item {
  background-color: #EEF7FF;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1rem;
  border-radius: 7px;
}

#about .about1 .container .gallery .item .top img {
  width: 20%;
}

#about .about1 .container .gallery .item .item_title {
  color: black;
  font-weight: bold;
  text-align: center;
}

#about .about1 .container .gallery .item .item_text {
  font-size: 20px;
  text-align: center;
}

#about .about1 .container .gallery .item #ctaWpp {
  margin: auto auto 0;
  width: 70%;
}

#about .about2 {
  background: url("../../assets/about2_bg.png") no-repeat center center;
  background-size: cover;
}

#about .about2 .container .title {
  color: white;
  text-align: center;
}

#about .about2 .container .text {
  color: white;
  text-align: center;
  margin-top: -1rem;
}

#about .about2 .container #ctaWpp {
  margin: 0 auto;
}

#about .about3 .container .title {
  text-align: center;
}

#about .about3 .container .gallery {
  display: grid;
  align-items: center;
  grid-template-columns: 30% 70%;
}

#about .about3 .container .gallery .left {
  text-align: left;
}

#about .about3 .container .gallery .left img {
  width: 80%;
}

#about .about3 .container #ctaWpp {
  margin: 0 auto;
}

#about .about4 {
  background-color: #F4F9FF;
}

#about .about4 .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
}

#about .about4 .container .text {
  font-size: 20px;
}

#about .about4 .container .title {
  font-size: 30px;
}

#about .about4 .container .left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about4 .container .left .map {
  height: 100%;
  width: 100%;
  border-radius: 7px;
  translate: -0;
}

#about .about4 .container .right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about4 .container .right .title.title2 {
  margin: 1rem 0;
  font-size: 30px;
}

#about .about4 .container .right .cta {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#about .about4 .container .right .cta button {
  width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .gallery {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  #about .about1 .container .gallery .item .item_text {
    font-size: 18px;
  }

  #about .about1 .container .gallery .item #ctaWpp {
    width: 60%;
  }

  #about .about3 .container .gallery {
    grid-template-columns: 100%;
    gap: 2rem;
  }

  #about .about3 .container .gallery .left {
    text-align: center;
  }

  #about .about3 .container .gallery .left img {
    width: 70%;
  }

  #about .about4 .container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  #about .about4 .container .text {
    text-align: center;
    font-size: 18px;
  }

  #about .about4 .container .title {
    font-size: 25px;
  }

  #about .about4 .container .left .map {
    height: 300px;
  }

  #about .about4 .container .right .title.title2 {
    font-size: 23px;
  }

  #about .about4 .container .right .cta {
    flex-direction: column;
  }

  #about .about4 .container .right .cta button {
    width: 60%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .gallery .item {
    gap: 1rem;
  }

  #about .about1 .container .gallery .item .top img {
    width: 25%;
  }

  #about .about1 .container .gallery .item .item_text {
    font-size: 16px;
  }

  #about .about1 .container .gallery .item #ctaWpp {
    width: 90%;
  }

  #about .about4 .container .left .map {
    height: 250px;
  }

  #about .about4 .container .text {
    font-size: 16px;
  }

  #about .about4 .container .title {
    font-size: 20px;
  }

  #about .about4 .container .right .title.title2 {
    font-size: 16px;
  }

  #about .about4 .container .right .cta button {
    width: 80%;
  }
}